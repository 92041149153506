import { EnvService } from '@localazy/components';

export default function() {
  /**
   * The only way out of static hell leads through the seven circles of hell.
   * Orgies of blood and fire, the stench of burnt flesh, the howls of pain.
   *
   * Originally, libraries relied on a globally created object (process.env) in the global namespace,
   * which contained all environment variables (global namespace pollution). This is no longer possible,
   * so it is necessary to pass environment values to the library's entry point. Since most parts
   * are called statically, the environment values also need to be stored statically.
   */
  EnvService.updateMode(process.env.APP_MODE || '');
  EnvService.updateNodeEnv(process.env.NODE_ENV || '');
  EnvService.updateDirectusConnectorServiceUrl(process.env.VUE_APP_DIRECTUS_CONNECTOR_SERVICE_URL || '');
  EnvService.updateGhostDirectusUrl(process.env.VUE_APP_GHOST_DIRECTUS_URL || '');
  EnvService.updateGhostUrl(process.env.VUE_APP_GHOST_URL || '');
  EnvService.updateGhostContentApi(process.env.VUE_APP_GHOST_CONTENT_API || '');
}
