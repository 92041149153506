
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { Integration, sortItems } from '@localazy/directus-service';
import RelationNavigationItemTypeIntegration from '@/modules/navigation/components/RelationNavigationItemTypeIntegration.vue';

export default Vue.extend({
  name: 'RelationNavigationItemTypeIntegrations',
  components: {
    RelationNavigationItemTypeIntegration
  },
  props: {
    items: {
      type: Array as Prop<Integration[]>,
      required: true
    }
  },
  computed: {
    sortedItems(): Integration[] {
      return sortItems(this.items);
    }
  }
});
