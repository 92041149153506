
import Vue, { PropType } from 'vue';
import { Brand } from '@localazy/directus-service';

export default Vue.extend({
  name: 'FeaturedBrand',
  props: {
    brand: {
      type: Object as PropType<Brand>,
      required: true
    }
  }
});
