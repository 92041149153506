
import Vue from 'vue';
import { LocIcon } from '@localazy/components';
import { Prop } from 'vue/types/options.d';
import { truncate } from '@localazy/core';
import { AlertTypes } from '@/modules/alerts/models/alert-types';
import IAlert from '@/modules/alerts/models/alert';

interface IData {
  AlertTypes: typeof AlertTypes;
}

export default Vue.extend({
  name: 'AppAlertItem',
  components: {
    LocIcon
  },
  props: {
    item: {
      type: Object as Prop<IAlert>,
      required: true
    }
  },
  data(): IData {
    return {
      AlertTypes
    };
  },
  computed: {
    message(): string {
      return truncate(this.item.title.toString(), 120);
    },
    iconName(): string {
      switch (this.item.type) {
        case AlertTypes.Info:
          return 'info';
        case AlertTypes.Success:
          return 'check-circle';
        case AlertTypes.Warning:
          return 'warning-amber';
        case AlertTypes.Error:
          return 'error-circle';
        default:
          throw new Error(`Unknown alert type: ${this.item.type}`);
      }
    },
    iconClasses(): string {
      switch (this.item.type) {
        case AlertTypes.Info:
          return 'fill-primary';
        case AlertTypes.Success:
          return 'fill-success';
        case AlertTypes.Warning:
          return 'fill-warning';
        case AlertTypes.Error:
          return 'fill-error';
        default:
          throw new Error(`Unknown alert type: ${this.item.type}`);
      }
    },
    typeClasses(): string {
      switch (this.item.type) {
        case AlertTypes.Info:
          return 'app-alert--info text-primary border-primary-lighten-3 bg-primary-lighten-5';
        case AlertTypes.Success:
          return 'app-alert--success text-success border-success-lighten-3 bg-success-lighten-5';
        case AlertTypes.Warning:
          return 'app-alert--warning text-warning border-warning-lighten-3 bg-warning-lighten-5';
        case AlertTypes.Error:
          return 'app-alert--error text-error border-error-lighten-3 bg-error-lighten-5';
        default:
          return '';
      }
    },
    closeAlertClasses(): string {
      switch (this.item.type) {
        case AlertTypes.Info:
          return 'fill-primary-lighten-3 hover:fill-primary-lighten-1 hover:bg-primary-lighten-4';
        case AlertTypes.Success:
          return 'fill-success-lighten-3 hover:fill-success-lighten-1 hover:bg-success-lighten-4';
        case AlertTypes.Warning:
          return 'fill-warning-lighten-3 hover:fill-warning-lighten-1 hover:bg-warning-lighten-4';
        case AlertTypes.Error:
          return 'fill-error-lighten-3 hover:fill-error-lighten-1 hover:bg-error-lighten-4';
        default:
          return '';
      }
    }
  },
  mounted() {
    this.startItemTimeout();
  },
  methods: {
    startItemTimeout() {
      if (this.item.delay !== undefined && this.item.delay > 0) {
        setTimeout(() => this.$emit('remove', this.item.id), this.item.delay);
      }
    },
    onClick() {
      return this.$emit('click', this.item.id);
    },
    onRemove() {
      return this.$emit('remove', this.item.id);
    }
  }
});
