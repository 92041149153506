
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { NavigationItemsGroup as INavigationItemsGroup, sortItems } from '@localazy/directus-service';
import NavigationItemsGroup from '@/modules/navigation/components/NavigationItemsGroup.vue';

export default Vue.extend({
  name: 'NavigationSubsection',
  components: {
    NavigationItemsGroup
  },
  props: {
    groups: {
      type: Array as Prop<INavigationItemsGroup[]>,
      required: true
    }
  },
  computed: {
    sortedGroups(): INavigationItemsGroup[] {
      return sortItems(this.groups);
    }
  }
});
