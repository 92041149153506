import { AnalyticsService } from '@localazy/core';
import isLoggedIn from '@/functions/auth/is-logged-in';
import Cookies from 'browser-cookies';

const TRACKING_PREFERENCES_COOKIE = 'tracking-preferences';
const DISABLE_REF_COOKIE = 'disable-analytics-ref';
const urlPatterns = ['sticker-maker']; // Add more patterns if needed in the future

export default function () {
  const currentUrl = window.location.href;
  const isMatchingPattern = urlPatterns.some(pattern => currentUrl.includes(pattern));
  const hasTrackingPreferences = Cookies.get(TRACKING_PREFERENCES_COOKIE);
  const hasDisableAnalyticsCookie = Cookies.get(DISABLE_REF_COOKIE);
  const wasNeverInitalized = !hasTrackingPreferences && !hasDisableAnalyticsCookie;

  if (wasNeverInitalized) {
    if (isMatchingPattern) {
      Cookies.set(DISABLE_REF_COOKIE, 'true', { expires: 99999 });
    } else if (!hasDisableAnalyticsCookie) {
      initAnalytics();
    }
  } else if (!hasDisableAnalyticsCookie) {
    initAnalytics();
  }
}

function initAnalytics() {
  AnalyticsService.initAnalytics({
    id: process.env.VUE_APP_SEGMENT || '',
    debug: false,
    skipConsent: true,
    enabledIntegrations: {
      posthog: process.env.VUE_APP_POSTHOG_ENABLED === 'true' ? 'enabled' : 'disabled'
    },
    posthogConfig: {
      key: process.env.VUE_APP_POSTHOG_ID || '',
      url: process.env.VUE_APP_POSTHOG_URL || '',
      autocapture: true,
      capture_pageview: true,
      disable_session_recording: !isLoggedIn()
    }
  });
}

AnalyticsService.posthog.onFeatureFlags(() => {
  if (AnalyticsService.posthog.isFeatureEnabled('web-session-recording')) {
    AnalyticsService.posthog.startSessionRecording();
  }
});
