import fetchAuthUser from '@/functions/auth/fetch-auth-user';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import AuthUser, { AuthenticatedUser } from '@/modules/@common/models/auth-user';
import updateUserInIntercom from '@/functions/update-user-in-intercom';
import IAlert from '@/modules/alerts/models/alert';
import { UserBase, AnalyticsService } from '@localazy/core';
import UserService from '@/functions/auth/user-service';
import fetchAuthToken from '@/functions/auth/fetch-auth-token';
import { ModeService } from '@localazy/directus-service';

interface IState {
    isArticleSubsectionMenuOpen: boolean;
    authUser: AuthUser | null;
    authToken: string | null;
    localazyUser: UserBase | null;
    alerts: IAlert[];
    isFetchingAuth: boolean;
}

export const state = (): IState => ({
  isArticleSubsectionMenuOpen: false,
  authUser: null,
  authToken: null,
  localazyUser: null,
  alerts: [],
  isFetchingAuth: false
});

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  CHANGE_ARTICLE_SUBSECTION_MENU_STATE: (state, isOpen: boolean) => (state.isArticleSubsectionMenuOpen = isOpen),
  SET_AUTH_USER: (state, authUser: AuthUser) => (state.authUser = authUser),
  SET_AUTH_TOKEN: (state, authToken: string) => (state.authToken = authToken),
  REMOVE_ALERT: (state, id: string) => { state.alerts = state.alerts.filter(item => item.id !== id); },
  PUSH_ALERT: (state, data: IAlert) => { state.alerts.push(data); },
  SET_LOCALAZY_USER: (state, user: UserBase) => (state.localazyUser = user),
  SET_IS_FETCHING_AUTH: (state, inProgress: boolean) => (state.isFetchingAuth = inProgress)
};

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit({ dispatch }, { route, req }) {
    await dispatch('fetchUserForServer', { route, req });
    await dispatch('initDirectusService');
  },

  async nuxtClientInit({ dispatch }) {
    await dispatch('fetchUserForClient');
    await dispatch('initDirectusService');
  },
  setAuthUser({ commit }, authUser: AuthUser) {
    commit('SET_AUTH_USER', authUser);
    updateUserInIntercom(authUser);
  },
  isFetchingAuth({ commit }, inProgress: boolean) {
    commit('SET_IS_FETCHING_AUTH', inProgress);
  },
  async fetchAuthUserSsr({ state, dispatch }) {
    if (state.authUser === null) {
      const authUser = await UserService.getAuthUser();
      await dispatch('setAuthUser', authUser);
      if (authUser && 'result' in authUser && authUser.result) {
        sendIdentifyEvent(authUser);
      }
    }
  },
  async fetchAuthUser({ state, dispatch }, cookie?: string) {
    /**
     * In the case the user was already fetched (e.g. on the server side),
     * we don't need to fetch it again.
     */
    if (state.authUser === null) {
      const authUser = await fetchAuthUser(cookie
        ? {
            headers: {
              Cookie: cookie || ''
            }
          }
        : undefined);
      await dispatch('setAuthUser', authUser);
      if (authUser && 'result' in authUser && authUser.result) {
        sendIdentifyEvent(authUser);
      }
    }
  },
  async fetchAuthToken({ state }, cookie?: string) {
    if (state.authUser?.result === true) {
      const authToken = await fetchAuthToken(cookie
        ? {
            headers: {
              cookie: cookie || ''
            }
          }
        : undefined);
      (globalThis as any).PAGE_AUTH_TOKEN = authToken;
    }
  },
  async fetchLocalazyUser({ commit, state }) {
    if (state.authUser?.result === true) {
      const user = await UserService.getUser({ slug: state.authUser.id });
      commit('SET_LOCALAZY_USER', user);
    }
    return state.localazyUser;
  },

  async fetchUserForServer({ dispatch }, { route, req }) {
    const includedPaths = ['/ambassador/action'];

    // Fetch user data if the route is included in the array
    if (includedPaths.some(path => route.path.includes(path))) {
      (globalThis as any).PAGE_AUTH_TOKEN = req.headers['x-localazy-token'] || process.env.VUE_APP_TEST_TOKEN || '';
      if ((globalThis as any).PAGE_AUTH_TOKEN) {
        await dispatch('isFetchingAuth', true);
        await dispatch('fetchAuthUserSsr');
        await dispatch('fetchLocalazyUser');
        await dispatch('isFetchingAuth', false);
      }
    }
  },

  async fetchUserForClient({ dispatch }) {
    /**
     * Warning: Do not send cookies to the client side for security reasons.
     */
    await dispatch('isFetchingAuth', true);
    await dispatch('fetchAuthUser');
    await dispatch('fetchAuthToken');
    await dispatch('fetchLocalazyUser');
    await dispatch('isFetchingAuth', false);
  },

  initDirectusService() {
    /**
    * This is an ugly way to initialize service. Once DI container is implemented, this will be eradicated.
    */
    ModeService.updateMode(process.env.APP_MODE || 'production');
  }
};

export const getters: GetterTree<RootState, RootState> = {
  isLoggedIn(state: IState): boolean {
    return state.authUser !== null && state.authUser.result === true;
  }
};

const sendIdentifyEvent = (user: AuthenticatedUser) => {
  AnalyticsService.identify(user.id, {
    email: user.email,
    name: user.name,
    slug: user.slug
  }, {
    Intercom: {
      user_hash: user.intercomHash || ''
    }
  });
};
