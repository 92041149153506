
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { LocIcon, LocTransitionContent, LocDropdown } from '@localazy/components';
import { NavigationSection, NavigationItemsGroup, NavigationItemsGroupJunction } from '@localazy/directus-service';
import NavigationSectionSimpleLink from '@/modules/navigation/components/NavigationSectionSimpleLink.vue';
import NavigationSubsection from '@/modules/navigation/components/NavigationSubsection.vue';
import NavigationSubsectionArrow from '@/modules/navigation/components/NavigationSubsectionArrow.vue';
import NavigationStateService from '@/modules/navigation/services/navigation-state-service';

interface IData {
  isExtended: boolean;
  isDropdownOpen: boolean;
  dropdownCloseTrigger: null | Function;
}

export default Vue.extend({
  name: 'NavigationSection',
  components: {
    LocIcon,
    NavigationSectionSimpleLink,
    NavigationSubsection,
    NavigationSubsectionArrow,
    LocTransitionContent,
    LocDropdown
  },
  props: {
    section: {
      type: Object as Prop<NavigationSection>,
      required: true
    }
  },
  data(): IData {
    return {
      isExtended: false,
      isDropdownOpen: false,
      dropdownCloseTrigger: null
    };
  },
  mounted() {
    NavigationStateService.getInstance().onCollapseAll(() => {
      this.isExtended = false;
      if (this.dropdownCloseTrigger) {
        this.dropdownCloseTrigger();
      }
    });
  },
  computed: {
    isSimpleLink(): boolean {
      return !!this.section.url && !!this.section.url_text;
    },
    groups(): NavigationItemsGroup[] {
      return this.section.groups.map((group:NavigationItemsGroupJunction) => group.navigation_items_group_id);
    }
  },
  methods: {
    attachDropdownCloseTrigger(trigger: Function) {
      this.dropdownCloseTrigger = trigger;
    }
  }
});
