/* eslint-disable no-use-before-define */
import Vue from 'vue';
import { breakpoints } from '@localazy/components';

export default class NavigationStateService {
  private static instance: NavigationStateService;
  vueInstance: any;

  private constructor() {
    this.vueInstance = new Vue(
      {
        data: {
          isMobileMenuOpen: false
        },
        computed: {
          isLg(): boolean {
            return breakpoints.lg;
          }
        },
        watch: {
          isLg: {
            handler: function () {
              /** Reset state when the layout is changing */
              if (this.instance) {
                this.instance.closeMobileMenu();
                this.instance.collapseAll();
              }
            }
          }
        }
      }
    );
  }

  public static getInstance(): NavigationStateService {
    if (!NavigationStateService.instance) {
      NavigationStateService.instance = new NavigationStateService();
    }
    return NavigationStateService.instance;
  }

  public collapseAll() {
    this.vueInstance.$emit('collapse-all');
  }

  public onCollapseAll(callback: Function) {
    this.vueInstance.$on('collapse-all', callback);
  }

  public closeMobileMenu() {
    this.setMobileMenuState(false);
  }

  public setMobileMenuState(isOpen: boolean) {
    if (this.vueInstance.isMobileMenuOpen !== isOpen) {
      this.vueInstance.$emit('toggle-mobile-menu', isOpen);
      if (!isOpen) {
        this.collapseAll();
      }
      this.vueInstance.isMobileMenuOpen = isOpen;
    }
  }

  public toggleMobileMenu() {
    this.setMobileMenuState(!this.vueInstance.isMobileMenuOpen);
  }

  public onToggleMobileMenu(callback: Function) {
    this.vueInstance.$on('toggle-mobile-menu', callback);
  }
}
