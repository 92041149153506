
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import SimplifiedBlogCard from '@/modules/blog/components/SimplifiedBlogCard.vue';
import { IBlogPost } from '@localazy/components';
import BlogService from '@/modules/blog/services/blog-service';
import { BlogPostNavigationItemType as IBlogPostNavigationItemType } from '@localazy/directus-service';

interface IData {
  post: IBlogPost | null;
}

export default Vue.extend({
  name: 'BlogPostNavigationItemType',
  components: {
    SimplifiedBlogCard
  },
  props: {
    item: {
      type: Object as Prop<IBlogPostNavigationItemType>,
      required: true
    }
  },
  async fetch() {
    await this.resolvePost();
  },
  data(): IData {
    return {
      post: null
    };
  },
  computed: {
    showAuthorAvatar(): boolean {
      return this.item.show_author_img;
    }
  },
  methods: {
    async resolvePost() {
      let post: IBlogPost[] = [];
      if (this.item.blog_post_tag === '[USE LATEST FEATURED]') {
        post = await BlogService.fetchHighlightBlogPosts({ limit: 1 });
      } else {
        post = await BlogService.fetchPostsForTag(this.item.blog_post_tag, { limit: 1 });
      }

      if (post.length > 0) {
        this.post = post[0];
      }
    }
  }
});
