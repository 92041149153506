
import Vue, { VueConstructor } from 'vue';
import { LocIcon } from '@localazy/components';
import { Prop } from 'vue/types/options.d';
import { TextLinkNavigationItemType as ITextLinkNavigationItemType } from '@localazy/directus-service';
import isAbsoluteUrl from '@/modules/navigation/utils/is-absolute-url';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export default Vue.extend({
  name: 'TextLinkNavigationItemType',
  components: {
    LocIcon
  },
  props: {
    item: {
      type: Object as Prop<ITextLinkNavigationItemType>,
      required: true
    }
  },
  computed: {
    isAbsoluteLink(): boolean {
      return isAbsoluteUrl(this.item.url);
    },
    linkComponent(): string | VueConstructor {
      if (this.isAbsoluteLink) {
        return 'a';
      }
      return NavigationNuxtLink;
    },
    componentAttrs(): object {
      if (this.isAbsoluteLink) {
        return {
          href: this.item.url
        };
      }
      return {
        to: this.item.url
      };
    }
  }
});
