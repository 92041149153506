
import Vue, { VueConstructor } from 'vue';
import { Prop } from 'vue/types/options.d';
import {
  RelationNavigationItemType, RelationItem, NavigationItemsGroup,
  Integration, Persona, Product, DocSection, TermLandingPage
} from '@localazy/directus-service';
import RelationNavigationItemTypeDocsSection from '@/modules/navigation/components/RelationNavigationItemTypeDocsSection.vue';
import RelationNavigationItemTypeIntegrations from '@/modules/navigation/components/RelationNavigationItemTypeIntegrations.vue';
import RelationNavigationItemTypeTile, { RelationNavigationItemTypeTile as RelationNavigationItemTypeTileType } from '@/modules/navigation/components/RelationNavigationItemTypeTile.vue';
import SimpleLinkNavigationItemType, { SimpleLinkType } from '@/modules/navigation/components/SimpleLinkNavigationItemType.vue';

export default Vue.extend({
  name: 'RelationNavigationItemType',
  components: {
    RelationNavigationItemTypeDocsSection,
    RelationNavigationItemTypeIntegrations,
    RelationNavigationItemTypeTile
  },
  props: {
    item: {
      type: Object as Prop<RelationNavigationItemType>,
      required: true
    },
    group: {
      type: Object as Prop<NavigationItemsGroup>,
      required: true
    }
  },
  computed: {
    /**
     * Get the collection name of the first item
     * Don't mix different item types
     */
    collection(): string {
      return this.item.item[0].collection;
    },
    relationItem(): RelationItem {
      return this.item.item[0].item;
    },
    relationItemComponent(): VueConstructor {
      switch (this.collection) {
        case 'docs_section':
          return RelationNavigationItemTypeDocsSection;
        case 'personas': {
          if (this.group.represent_as_tile) {
            return RelationNavigationItemTypeTile;
          } else {
            return SimpleLinkNavigationItemType;
          }
        }
        case 'platforms':
          return RelationNavigationItemTypeIntegrations;
        case 'products':
        case 'term_landing_page':
          return SimpleLinkNavigationItemType;
        default:
          throw new Error(`Unsupported collection: ${this.collection}`);
      }
    },
    relationItemProps(): object {
      switch (this.collection) {
        case 'docs_section': {
          const item = this.relationItem as DocSection;
          return { item };
        }
        case 'personas': {
          const typedItem = this.relationItem as Persona;
          if (this.group.represent_as_tile) {
            const item: RelationNavigationItemTypeTileType = {
              name: this.item.name,
              icon: typedItem.icon,
              slug: `/for/${typedItem.slug}`
            };
            return { item };
          } else {
            const item: SimpleLinkType = {
              url: `/for/${typedItem.slug}`,
              url_text: this.item.name,
              show_favorite_icon: false
            };
            return { item };
          }
        }
        case 'platforms': {
          const items = this.item.item.map(item => item.item) as Integration[];
          return { items };
        }
        case 'products': {
          const typedItem = this.relationItem as Product;
          const item: SimpleLinkType = {
            url: `/features/${typedItem.slug}`,
            url_text: this.item.name,
            show_favorite_icon: false
          };
          return { item };
        }
        case 'term_landing_page': {
          const typedItem = this.relationItem as TermLandingPage;
          const item: SimpleLinkType = {
            url: `/term/${typedItem.slug}`,
            url_text: this.item.name,
            show_favorite_icon: false
          };
          return { item };
        }
        default:
          throw new Error(`Unsupported collection: ${this.collection}`);
      }
    }
  }
});
