import { LocalStorageService } from '@localazy/components';
import AnalyticsService from '@/plugins/analytics-service';
import isLoggedIn from '@/functions/auth/is-logged-in';

const STORAGE_KEY = 'll-engage';

type EngageData = {
  timestamp: number;
  maxScroll: number;
  sessionDuration: number;
};

export default function engageTracker() {
  const data: EngageData = {
    timestamp: Date.now(),
    maxScroll: 0,
    sessionDuration: 0
  };

  if (!isEngageSent()) {
    // Set initial data
    const interval = setInterval(() => {
      data.sessionDuration = Math.round((Date.now() - data.timestamp) / 1000);
      data.maxScroll = calcMaxScroll(data);

      if (shouldTrackEngagement(data)) {
        clearInterval(interval);
        trackEngagement(data);
        setEngageFlag();
      }
    }, 1000);
  }
}

function getPageScrollPercentage(): number {
  const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
  const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  return Math.round((scrollPosition / totalHeight) * 100);
}

function isEngageSent(): boolean {
  return JSON.parse(LocalStorageService.getItem(STORAGE_KEY) || 'false') === true;
}

function shouldTrackEngagement(data: EngageData): boolean {
  return !isEngageSent() && !isLoggedIn() && data.sessionDuration >= 30 && data.maxScroll >= 0.25;
}

function trackEngagement(data: EngageData) {
  AnalyticsService.trackEvent('User Engaged with Site', {
    session_duration: data.sessionDuration,
    max_scroll: data.maxScroll,
    category: 'User'
  });
}

function setEngageFlag() {
  LocalStorageService.setItem(STORAGE_KEY, 'true');
}

function calcMaxScroll(data: Pick<EngageData, 'maxScroll'>): number {
  return Math.max(data.maxScroll, getPageScrollPercentage());
}
