
import Vue from 'vue';
import SocialProofSlider from '@/modules/login/components/SocialProofSlider.vue';
import { Brand, FeaturedBrandsService } from '@localazy/directus-service';

interface IData {
  brands: Brand[];
}

export default Vue.extend({
  name: 'SocialProofContainer',
  components: {
    SocialProofSlider
  },
  async fetch () {
    this.brands = await FeaturedBrandsService.fetchAllFeaturedBrands();
  },
  data (): IData {
    return {
      brands: []
    };
  }
});
